import env from "config/env.json";
import site from "config/site.js"

export default {
  namespaced: true,
  state: {
    applicationReady: false,
    applicationLoaded: true,
    loading: [],
  },
  mutations: {
    set(state, data) {
      state[data.key] = data.value;
    },
    loading(state, data = true) {
      if (data) {
        state.loading.push(1);
        return;
      }
      state.loading.shift();
    },
  },
  actions: {
    set(context, data) {
      context.commit("set", data);
    },
    loading(context, data) {
      context.commit("loading", data);
    },
  },
  getters: {
    siteData: (state, getters) => {
      return site
    },
    siteLanguage: (state, getters) => {
      return getters.siteData.language;
    },
    siteName: (state, getters) => {
      return getters.siteData.siteName
    },
    seoTitle: (state, getters) => {
      return getters.siteData.seoTitle
    },
    seoDescription: (state, getters) => {
      return getters.siteData.seoDescription
    },
    seoKeywords: (state, getters) => {
      return getters.siteData.seoKeywords
    },
    seoPhoto: (state, getters) => {
      return getters.siteData.seoPhoto
    },
    loading: (state) => {
      if (state.loading.length == 0) return false;
      return true;
    },
    applicationReady: (state) => state.applicationReady,
    applicationLoaded: (state) => state.applicationLoaded,
  },
};
