<template>
  <v-app>
    <Nav />

    <v-main v-if="applicationReady" class="pt-0">
      <router-view></router-view>
    </v-main>

    <!--loader-->
    <v-overlay :value="loading" :style="loadingStyle">
      <v-progress-circular indeterminate size="80"></v-progress-circular>
    </v-overlay>

    <AppExtra v-if="applicationLoaded"></AppExtra>
  </v-app>
</template>

<script>
import AppExtra from "@/AppExtra.vue";
import Nav from "@/components/nav.vue";

export default {
  data: () => ({}),
  computed: {
    applicationReady() {
      return this.$store.getters["base/applicationReady"];
    },
    loadingStyle() {
      return {
        zIndex: 999999999,
      };
    },
    loading() {
      return this.$store.getters["base/loading"];
    },
    applicationLoaded() {
      return this.$store.getters["base/applicationLoaded"];
    },
  },
  watch: {
    $route: {
      deep: true,
      handler(after, before) {
        if (after.name != before.name) return this.goTop();
        if (_isEqual(after.params, before.params) === false)
          return this.goTop();
        if (_isEqual(after.query, before.query) === false) return this.goTop();
      },
    },
  },
  async mounted() {},
  components: {
    AppExtra,
    Nav,
  },
  methods: {
    async goTop() {
      this.$vuetify.goTo(0, {
        container: document.querySelector("body"),
        duration: 0,
      });
    },
  },
};
</script>
<style lang="sass" type="text/sass" src="@/assets/sass/index.sass"></style>
