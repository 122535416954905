<template>
  <div anchor-app-extra>
    <anchor-popup style="z-index: 204 !important"></anchor-popup>
    <vue-snotify></vue-snotify>
  </div>
</template>

<script lang="babel" type="text/babel">
import Vue from 'vue'
import AnchorPopup from 'plugins/anchorPopup/installer.js'

import Snotify from 'vue-snotify'
  Vue.use(Snotify, {
    toast: { 
      timeout: 5000, 
      titleMaxLength: 32,
      showProgressBar: false,
      position: 'rightBottom',
    },
  })

export default {
  created() {
    this.initPopup()
  },
  computed: {
    anchorPopupDefaultConfig() {
      return {
        width: '350',
        applyLabel: this.$t('action.apply'),
        closeLabel: this.$t('action.close'),
      }
    },
  },
  methods: {
    initPopup() {
      Vue.use(AnchorPopup, this.anchorPopupDefaultConfig)
      this.$apopup.init({
        width: '350',
        applyLabel: this.$t('action.apply'),
        closeLabel: this.$t('action.close'),
      })
    },

  },
}
</script>
